import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const HowTwitterInstantWinWorks = ({ location }) => {
  const title = "Here's how Twitter Instant Win works"
  const list = [
    { title: "Frequently Asked Questions", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="During the campaign period, the program continues to collect data on the specified tweets. The collected data can be output in CSV format, allowing you to view data such as the number of participants and distribution of participants' followers by time period."
          pagepath={location.pathname}
        />
        <Breadcrumb title="Frequently Asked Questions" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              How does Twitter Instant Win work?
            </h1>
            <p className="qa-page__answer">
              During the campaign period, PARKLoT's system linked to Twitter
              collects data on users who retweet the specified tweets.
              <br />
              After acquiring retweeted user data, the program automatically
              draws lots according to pre-set lottery probabilities. Lottery
              results are sent via direct message using Twitter's API.
            </p>
            <p className="qa-page__text">
              Businesses that provide Twitter extensions (instant win, automatic
              DM, hashtag collection, etc.), including our company, use the API
              provided by Twitter to provide instant win services. (
              <a
                href="https://help.twitter.com/en/rules-and-policies/twitter-api#:~:text=Twitter's API platform provides broad access to public Twitter data that provider users have set up to be shared globally; the API allows users to manage private Twitter information (such as direct messages) and also makes this information available to authorized developers. information to authorized developers."
                target="_blank"
                rel="noreferrer"
              >
                For a detailed explanation of TwitterAPI, click here.
              </a>
              )
              <br />
            </p>
            <p className="qa-page__text">
              If you run a retweet campaign, such as Instant Win, or create a
              bot application that responds to specific keywords, and need to
              make a large number of Twitter API calls to make the application
              work, you will need to request Twitter to release the API limit.
            </p>

            <p className="qa-page__text">
              <h2 className="qa-page__h2">About Twitter API</h2>
              Twitter's API platform provides broad access to general Twitter
              data that provider users have set to share globally. The API
              allows users to manage private Twitter information (e.g., direct
              messages) and also makes this information available to authorized
              developers.
            </p>

            <div className="qa-page__text">
              <h2 className="qa-page__h2">What you can do with TwitterAPI</h2>
              The Twitter API allows you to utilize a variety of data on Twitter
              as part of your own programs. Here is a sampling of some of them
              that may be particularly relevant to social networking campaigns
              and instant wins.
              <br />
              <br />
              <ol className="qa-page__ol">
                <li className="toc__item">Post a Tweet</li>
                <li className="toc__item">
                  Check if the specified user is following your account
                </li>
                <li className="toc__item">
                  Obtain a list of users who "liked" the specified tweet
                </li>
                <li className="toc__item">
                  Obtain a list of users who "retweeted" the specified tweet
                </li>
                <li className="toc__item">
                  Retrieve a list of users who have quoted and retweeted the
                  specified tweet and the text posted
                </li>
                <li className="toc__item">
                  Upload media (still or video images)
                </li>
                <li className="toc__item">
                  Send a reply to the specified user
                </li>
                <li className="toc__item">Send DMs to specified users</li>
              </ol>
            </div>

            <div className="qa-page__text">
              <h2 className="qa-page__h2">About TwitterAPI Limits</h2>
              When using the Twitter API, certain restrictions on account
              operations are defined for the purpose of maintaining system
              reliability, as follows
              <br />
              <br />
              <ol className="qa-page__ol">
                <li className="toc__item">
                  <a
                    href="https://help.twitter.com/ja/rules-and-policies/twitter-limits#:~:text=Tweets: 2,400 tweets per day is the limit"
                    target="_blank"
                    rel="noreferrer"
                  >
                    2,400 replies/day if they are replies
                  </a>
                </li>
                <li className="toc__item">
                  <a
                    href="https://help.twitter.com/en/rules-and-policies/twitter-limits#:~:text=Direct messages (per day): 1,000 messages can be sent per day"
                    target="_blank"
                    rel="noreferrer"
                  >
                    If it is a DM, the maximum number of messages is 1000 per
                    day
                  </a>
                </li>
              </ol>
              <br />
              The above are typical examples of API restrictions, but&nbsp;
              <a
                href="https://help.twitter.com/en/rules-and-policies/twitter-limits"
                target="_blank"
                rel="noreferrer"
              >
                there are various other restrictions on the Twitter API&nbsp;
              </a>
              in addition to the above. These are limitations of the Twitter
              platform, not of PARKLoT's system.
              <br />
              Some restrictions are relaxed by obtaining a certification badge.
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default HowTwitterInstantWinWorks
